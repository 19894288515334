<template>
  <div>
    <h3>Påstand:</h3>
    <h2 class="paastander">Bærekraft og bærekraftsmålene må integreres mer i pensum.</h2> <!-- Expression/Question -->
    <vsa-list>
      <vsa-item>
        <vsa-heading>
          Les mer her
        </vsa-heading>

        <vsa-content>
          <div class="explaination">
            <p>
              Bærekraft er et begrep som blir ofte brukt når en prater om samfunnet. Det har i nyere tid blitt mer
              etterspørsel om kunnskap av bærekraft fra for eksempel næringslivet. UiO har vedtatt en ny klimastrategi
              for å arbeide for nye tiltak innen klima for å oppnå bærekraftsmålene.
            </p>
            <p>
              Spørsmålet er da, skal UiA fokusere mer konkret på bærekraft og integrere målene i pensum?
            </p>
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>
    <slot>></slot> <!-- Slot for react buttons -->
  </div>

</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: "Spm1",
  components:{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  }
}
</script>

<style scoped>

</style>